import SocketIO from 'socket.io-client';
const Config = require('~/config/default.env').default;
const Cookies = require('js-cookie');

export const userActions = async (context) => {
  const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

  const [vending_machine_device, vmcola_detail, vm_store_id] = await Promise.all([
    Cookies.get('vending_machine_device'),
    Cookies.get('location_detail_vm_cola'),
    Cookies.get('store_location_id'),
  ]);

  const isVendingMachine = vending_machine_device === 'true' || vending_machine_device === true;

  socket.on('connect', () => {
    socket.emit('join', 'vending_machine_' + (isVendingMachine ? vm_store_id : vmcola_detail));
  });

  const vm = context;

  socket.on('vending_machine_action', function (data) {
    if (['refresh', 'vm_cookie'].includes(data.action)) {
      vm.$router.push(isVendingMachine ? '/vending-machine/home' : '/vmcola');
      
      if (data.action === 'vm_cookie' && data.value && !isVendingMachine) {
        Cookies.set('store_detail_id', data.value);
      }

      setTimeout(() => {
        location.reload(true);
        socket.off('vending_machine_action');
      }, data.timeout || 2000);
    }
  });
};
