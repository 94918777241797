const patterns = [
  {
    ready: {
      actor: ['A', 'A', 'A'],
    },
    start: [
      {
        actor: ['B', 'A', 'D'],
      },
      {
        actor: ['B', 'B', 'A'],
      },
      {
        actor: ['C', 'A', 'A'],
      },
      {
        actor: ['B', 'B', 'D'],
      },
      {
        actor: ['B', 'B', 'A'],
      },
      {
        actor: ['A', 'B', 'A'],
      },
      {
        actor: ['B', 'C', 'D'],
      },
      {
        actor: ['A', 'C', 'A'],
      },
      {
        actor: ['B', 'C', 'A'],
      },
      {
        actor: ['B', 'D', 'A'],
      },
      {
        actor: ['A', 'B', 'A'],
      },
      {
        actor: ['B', 'A', 'D'],
      },
      {
        actor: ['B', 'D', 'A'],
      },
      {
        actor: ['D', 'A', 'A'],
      },
      {
        actor: ['D', 'D', 'C'],
      },
    ],
  },
  {
    ready: {
      actor: ['A', 'D', 'C'],
    },
    start: [
      {
        actor: ['B', 'C', 'A'],
      },
      {
        actor: ['D', 'B', 'C'],
      },
      {
        actor: ['A', 'A', 'B'],
      },
      {
        actor: ['A', 'B', 'C'],
      },
      {
        actor: ['D', 'A', 'B'],
      },
      {
        actor: ['B', 'C', 'B'],
      },
      {
        actor: ['B', 'B', 'B'],
      },
      {
        actor: ['B', 'A', 'A'],
      },
      {
        actor: ['C', 'B', 'B'],
      },
      {
        actor: ['B', 'D', 'B'],
      },
      {
        actor: ['A', 'B', 'A'],
      },
      {
        actor: ['B', 'B', 'C'],
      },
      {
        actor: ['A', 'A', 'B'],
      },
      {
        actor: ['A', 'C', 'B'],
      },
      {
        actor: ['A', 'B', 'A'],
      },
    ],
  },
  {
    ready: {
      actor: ['B', 'A', 'B'],
    },
    start: [
      {
        actor: ['A', 'A', 'C'],
      },
      {
        actor: ['D', 'C', 'A'],
      },
      {
        actor: ['A', 'A', 'C'],
      },
      {
        actor: ['C', 'B', 'B'],
      },
      {
        actor: ['A', 'A', 'B'],
      },
      {
        actor: ['B', 'B', 'A'],
      },
      {
        actor: ['B', 'A', 'B'],
      },
      {
        actor: ['A', 'B', 'A'],
      },
      {
        actor: ['A', 'B', 'C'],
      },
      {
        actor: ['B', 'B', 'B'],
      },
      {
        actor: ['A', 'B', 'B'],
      },
      {
        actor: ['A', 'A', 'A'],
      },
      {
        actor: ['B', 'A', 'D'],
      },
      {
        actor: ['A', 'B', 'C'],
      },
      {
        actor: ['A', 'B', 'A'],
      },
    ],
  },
];

export default patterns;
