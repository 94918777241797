<template>
  <div class="page--pimple-pop game bbb-pages">
    <div class="header">
      <div class="wrapper" style="float: left; width: 100%">
        <div class="score">
          <span v-if="isVnSource" class="score__text">ĐIỂM score__text</span>
          <span v-else class="score__text"
            >SCORE <span class="score__point"> {{ game.zit }}</span></span
          >
        </div>
        <a href="" class="close-button" style="margin-left: 600px" @click.prevent="forceQuit()"
          ><img src="/static/img/pimple-pop/close-button@2x.png" width="86"
        /></a>
      </div>
      <div class="progress-wrapper" style="width: 84%; float: left; padding-top: 28px">
        <div class="progress">
          <div class="progress-bar" :style="{ width: `${game.state.persenProgress}%` }"></div>
        </div>
      </div>
      <span class="header__timer" style="float: right">00:{{ game.time }}</span>
    </div>

    <div class="game-arena">
      <div class="game-arena__actor">
        <div class="game-arena__grid">
          <div v-for="(item, key) in game.area" :key="key" class="col-pimple" :class="{ [key]: true }">
            <a
              v-show="item.isShow"
              href=""
              class="actor"
              :class="[game.area[key].actor]"
              @contextmenu.prevent
              @click.prevent="
                getActor($event, { index: key, actor: game.area[key].actor, show: item.isShow, point: item.point })
              "
            >
              <img :src="image(game.area[key].actor)" />
            </a>
            <transition name="pointfade">
              <span v-show="item.zitShow" class="pimple-point"></span>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <img class="cart-footer" src="/static/img/soco-beauty-star/sbs-cart-store.png" />
    <div v-show="game.state.isReadyToPlay === false" class="overlay">
      <img v-if="game.countdown != 'GO!'" src="/static/img/soco-beauty-star/sbs-tap-beauties.png" />
      <span v-else class="text">{{ game.countdown }}</span>
    </div>
    <!-- <audio
        v-if="game.countdown == 'GO!'"
        src="https://s3-ap-southeast-1.amazonaws.com/images.soco.id/big-bang-boom/Big-Bang-Boom_BGM.ogg"
        preload="auto"
        autoplay
      ></audio> -->
  </div>
</template>

<script>
import config from '@/shared/pimple-pop/configBigBangBoom';
import VendingMachineTracker from '@/mixins/vending-machine-tracker';

import { userActions } from '@/helpers/user-actions';

const Random = require('random-js');
const random = new Random(Random.engines.mt19937().autoSeed());
let timerState;

export default {
  name: 'SocoBeautyStarGame',
  mixins: [VendingMachineTracker],
  data() {
    return {
      game: {
        actor: {},
        addPimple: this.addPimple,
        area: [],
        areaNumber: 6,
        code: {},
        gamePattern: 'A',
        countdown: 3,
        listNotAllowedAreaPimple: [],
        pimpleArea: [],
        randomArea: this.randomArea,
        readyPlay: this.readyPlay,
        removeAllPimple: this.removeAllPimple,
        removePimple: this.removePimple,
        resultsRandomArea: [],
        runTimer: this.runTimer,
        zit: 0,
        randomConfig: 0,
        start: this.start,
        state: {
          isReadyToPlay: false,
          persenProgress: 0,
          currentIndex: 0,
          patternIndex: null,
          mystery: null,
          arrIndexMystery: 0,
        },
        time: 15,
        playingTime: 15,
      },
    };
  },
  computed: {
    patterns() {
      return config[this.randomConfig];
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
    beautyAtmData() {
      return this.$store.state.beautyAtmData;
    },
    products() {
      if (this.beautyAtmData?.products?.length) {
        return this.beautyAtmData.products;
      }
      return [];
    },
  },
  watch: {
    products: {
      handler(newVal) {
        if (newVal.length) {
          newVal.filter((item) => {
            if (item.type === 'type-a') {
              const newActor = {
                'actor--cream': {
                  value: item.point,
                  image: item.image,
                },
              };
              const newCode = {
                A: {
                  name: 'actor--cream',
                },
              };
              this.game.actor = { ...this.game.actor, ...newActor };
              this.game.code = { ...this.game.code, ...newCode };
            }
            if (item.type === 'type-b') {
              const newActor = {
                'actor--carasun': {
                  value: item.point,
                  image: item.image,
                },
              };
              const newCode = {
                B: {
                  name: 'actor--carasun',
                },
              };
              this.game.actor = { ...this.game.actor, ...newActor };
              this.game.code = { ...this.game.code, ...newCode };
            }
            if (item.type === 'broken-star') {
              const newActor = {
                'actor--star': {
                  value: item.point,
                  image: item.image,
                },
              };
              const newCode = {
                C: {
                  name: 'actor--star',
                },
              };
              this.game.actor = { ...this.game.actor, ...newActor };
              this.game.code = { ...this.game.code, ...newCode };
            }
            if (item.type === 'broken-heart') {
              const newActor = {
                'actor--heart': {
                  value: item.point,
                  image: item.image,
                },
              };
              const newCode = {
                D: {
                  name: 'actor--heart',
                },
              };
              this.game.actor = { ...this.game.actor, ...newActor };
              this.game.code = { ...this.game.code, ...newCode };
            }
            return item;
          });
        }
      },
      immediate: true,
    },
    'game.state.isReadyToPlay'(newVal) {
      if (newVal) {
        this.start();
        this.runTimer();
      }
    },
  },
  mounted() {
    this.readyPlay();
    userActions(this);
  },
  created() {
    this.randomConfig = random.integer(0, 2);
    this.buildGameState();
  },
  methods: {
    image(item) {
      let findImage = '';
      if (this.game.actor && this.game.actor[item]?.image) {
        findImage = this.game.actor[item].image;
      }
      return findImage;
    },
    addPimple() {
      const stateAddPimple = setInterval(() => {
        const randArea = this.game.randomArea();
        let actorPattern;

        // if game finished
        if (this.game.time == '00') {
          clearInterval(stateAddPimple);
          this.game.removeAllPimple();
        }

        // if game not finished
        if (this.game.time != '00' && randArea) {
          const pattern_A = [
            ['B', 'B', 'A'],
            ['B', 'B', 'C'],
            ['D', 'A', 'A'],
            ['B', 'B', 'D'],
            ['B', 'B', 'A'],
            ['A', 'B', 'A'],
            ['B', 'C', 'D'],
            ['A', 'C', 'A'],
            ['B', 'C', 'A'],
            ['B', 'D', 'A'],
            ['A', 'B', 'A'],
            ['B', 'A', 'D'],
            ['B', 'D', 'A'],
            ['D', 'A', 'A'],
            ['D', 'D', 'C'],
          ];

          const pattern_B = [
            ['B', 'B', 'A'],
            ['B', 'B', 'C'],
            ['D', 'A', 'A'],
            ['A', 'B', 'C'],
            ['D', 'A', 'B'],
            ['B', 'C', 'B'],
            ['B', 'B', 'B'],
            ['B', 'A', 'A'],
            ['C', 'B', 'B'],
            ['B', 'D', 'B'],
            ['A', 'B', 'A'],
            ['B', 'B', 'C'],
            ['A', 'A', 'B'],
            ['A', 'C', 'B'],
            ['A', 'B', 'A'],
          ];

          const pattern_C = [
            ['B', 'B', 'A'],
            ['B', 'C', 'D'],
            ['D', 'A', 'A'],
            ['C', 'B', 'D'],
            ['A', 'A', 'B'],
            ['B', 'B', 'A'],
            ['D', 'A', 'D'],
            ['A', 'B', 'C'],
            ['A', 'B', 'C'],
            ['B', 'C', 'D'],
            ['A', 'B', 'D'],
            ['A', 'A', 'C'],
            ['B', 'A', 'D'],
            ['A', 'B', 'C'],
            ['A', 'D', 'D'],
          ];

          for (let i = 0; i < randArea.length; i++) {
            if (this.gamePattern === 'C') {
              actorPattern = this.patterns.start[this.game.state.currentIndex]
                ? pattern_C[this.game.state.currentIndex][i]
                : false;
            } else if (this.gamePattern === 'B') {
              actorPattern = this.patterns.start[this.game.state.currentIndex]
                ? pattern_B[this.game.state.currentIndex][i]
                : false;
            } else {
              actorPattern = this.patterns.start[this.game.state.currentIndex]
                ? pattern_A[this.game.state.currentIndex][i]
                : false;
            }

            this.game.area[randArea[i]].isShow = true;
            this.game.area[randArea[i]].clickCount = 0;
            this.game.area[randArea[i]].actor = actorPattern ? this.game.code[actorPattern].name : '';

            this.game.pimpleArea.push(randArea[i]);
          }
        }

        this.game.state.currentIndex++;
      }, 1100);
    },
    buildGameState() {
      // set area
      for (let i = 0; i < this.game.areaNumber; i++) {
        if (this.$SO.inArray(i, this.game.listNotAllowedAreaPimple) > 0) {
          this.$set(this.game.area, i, { isShow: false, actor: null, isAllowed: false, zitShow: false, clickCount: 0 });
        } else {
          this.$set(this.game.area, i, { isShow: false, actor: null, isAllowed: true, zitShow: false, clickCount: 0 });
        }
      }
    },
    getActor(e, { index, actor, show, point }) {
      if (show && e.isTrusted) {
        this.game.area[index].clickCount = this.game.area[index].clickCount + 1;
        let zit = 0;

        zit = this.game.actor[actor].value;

        if (!isNaN(zit)) {
          this.game.zit += zit;

          if (
            (actor === 'actor--cream' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--carasun' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--star' && this.game.area[index].clickCount > 0) ||
            (actor === 'actor--heart' && this.game.area[index].clickCount > 0)
          ) {
            this.game.area[index].isShow = false;
            this.game.area[index].actor = null;
            this.game.area[index].zitShow = true;
            if (zit !== 0) {
              e.target.parentElement.nextElementSibling.innerHTML = zit > 0 ? '+' + zit : zit;
              setTimeout(() => {
                this.game.area[index].zitShow = false;
              }, 10);
            }
          }
        }
      }
    },
    randomArea() {
      // use recursive concept
      // if doesnt meet empty area this function always call itselt until find a empty area
      let results;

      if (this.game.resultsRandomArea.length >= 3) {
        try {
          this.game.resultsRandomArea = [];
        } catch (err) {}
      }

      const randomNum = random.integer(0, 5);

      if (this.game.pimpleArea.length > 9) {
        if (this.game.area[randomNum].isAllowed) {
          if (this.$SO.size(this.game.resultsRandomArea) < 4) {
            this.game.area[randomNum].isShow = true;
            this.game.resultsRandomArea.push(randomNum);
            if (this.game.resultsRandomArea.length === 3) {
              results = Array.prototype.slice.call(this.game.resultsRandomArea);
              return results;
            } else {
              return this.randomArea();
            }
          }
        } else {
          return this.randomArea();
        }
      } else {
        if (this.game.area[randomNum].isShow || !this.game.area[randomNum].isAllowed) {
          try {
            return this.randomArea();
          } catch (err) {}
        } else {
          if (this.$SO.size(this.game.resultsRandomArea) < 3) {
            this.game.area[randomNum].isShow = true;
            this.game.resultsRandomArea.push(randomNum);
            if (this.game.resultsRandomArea.length === 3) {
              results = Array.prototype.slice.call(this.game.resultsRandomArea);
              return results;
            } else {
              return this.randomArea();
            }
          }
        }
      }
    },
    readyPlay() {
      this.$set(this.game, 'time', this.game.playingTime);
      const readyState = setInterval(() => {
        if (this.$SO.type(this.game.countdown) === 'number') {
          this.game.countdown--;
        }

        if (this.$SO.type(this.game.countdown) === 'string') {
          this.game.state.isReadyToPlay = true;
          clearInterval(readyState);
        }

        if (this.game.countdown === 0) {
          this.game.countdown = 'GO!';
        }
      }, 1000);
    },
    removePimple() {
      // FIFO flow
      const stateRemovePimple = setInterval(() => {
        let pimple;
        const pimpleArea = Array.prototype.slice.call(this.game.pimpleArea, 0, 3);

        for (let i = 0; i < pimpleArea.length; i++) {
          pimple = this.game.area[pimpleArea[i]];
          if (pimple.isShow) {
            pimple.isShow = false;
            pimple.actor = null;
            pimple.clickCount = 0;
            this.game.pimpleArea.shift(); // FIFO
          }
        }

        if (this.game.time == '00') {
          clearInterval(stateRemovePimple);
          this.game.removeAllPimple();
        }
      }, 1200);
    },
    removeAllPimple() {
      for (let i = 0; i < this.game.area.length; i++) {
        setInterval(() => {
          this.game.area[i].isShow = false;
          this.game.area[i].actor = null;
        }, 1000);
      }
    },
    runTimer() {
      timerState = setInterval(() => {
        this.game.state.persenProgress += 100 / this.game.playingTime;
        this.game.time--;
        if (this.$SO.type(this.game.time) === 'number' && this.game.time < 10) {
          this.game.time = '0' + this.game.time;
        }
        // the finish state of the game
        if (this.game.time == '00') {
          clearInterval(timerState);
          setTimeout(() => {
            this.$store.commit('setZits', this.game.zit);
            if (this.isVnSource) {
              this.$router.push({ path: '/vending-machine/pimple-pop/finish', query: { isvn: 'true' } });
            } else {
              this.$router.push('/vending-machine/pimple-pop/finish');
            }
          }, 1000);
        }
      }, 1100);
    },
    start() {
      const randArea = this.game.randomArea();

      // Determine Pattern in the beginning of the game
      const patternChance = Math.floor(Math.random() * 100);
      if (patternChance >= 66) {
        this.gamePattern = 'A';
      } else if (patternChance > 33 && patternChance < 66) {
        this.gamePattern = 'B';
      } else {
        this.gamePattern = 'C';
      }

      for (let i = 0; i < randArea.length; i++) {
        // 50:50 chance to show item carasun or bliss in the beginning of game
        // the first pattern absolutely BBB
        this.game.area[randArea[i]].actor = 'actor--carasun';
        this.game.pimpleArea.push(randArea[i]);
      }

      this.game.addPimple();
      this.game.removePimple();
    },
    async forceQuit() {
      this.postTracker('play_close');
      clearInterval(timerState);
      this.$store.commit('setZits', 0);
      if (this.$route.query.isvn) {
        this.$router.push('/vending-machine-vn/home?isvn=true');
      } else {
        const path = (await Cookies.get('vending_machine_device')) == 'true' ? 'home' : 'login';
        this.$router.push('/vending-machine/' + path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bbb-pages {
  .header {
    height: 280px;
    background: #e2397d;
  }
  .score {
    height: 100px;
    background: #ffc1cf;
    color: #ca2366;
  }
  .progress-wrapper {
    background: transparent;
    padding: 20px;
  }
  .progress-bar {
    background: #ff9eb3;
  }
  .header__timer {
    color: #fff;
  }
  .score__point {
    color: #ca2366;
    font-size: 62px;
    margin-left: 15px;
  }
  .score__text {
    font-size: 36px;
  }

  .game-arena {
    background: #000 url('/static/img/soco-beauty-star/bg-sbs-game.png') no-repeat center -226px;
    background-size: 100% auto;
  }
  .game-arena__actor {
    background: none;
  }

  .actor {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: block;
    user-select: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: content;
      user-select: none;
    }
    &:not(.actor--star) img {
      filter: drop-shadow(-5px 10px 6px rgba(48, 0, 10, 0.7));
    }
    &:not(.actor--heart) img {
      filter: drop-shadow(-5px 10px 6px rgba(48, 0, 10, 0.7));
    }
  }

  .game-arena__grid {
    width: 980px;
    height: 980px;
    margin: 10px auto 0;
  }

  .actor--cream {
    opacity: 1;
    width: 58%;
    height: 55%;
    transform: rotate(20deg);
  }
  .actor--carasun {
    opacity: 1;
    width: 58%;
    height: 55%;
    transform: rotate(-20deg);
  }
  .actor--bliss {
    opacity: 1;
    width: 20%;
    height: 70%;
    transform: rotate(15deg);
  }
  .actor--star {
    opacity: 1;
    width: 50%;
    height: 50%;
  }
  .actor--heart {
    opacity: 1;
    width: 50%;
    height: 50%;
    transform: rotate(-15deg);
  }

  .pimple-point {
    position: absolute;
    z-index: 1;
    top: 0;
    left: calc(50% - 30px);
    color: #fff;
    font-family: 'brandontext-bold', sans-serif;
    font-size: 72px;
    line-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  .pointfade-enter-active,
  .pointfade-leave-active {
    transition: all 1s;
  }

  .pointfade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .col-pimple {
    width: calc(100% / 3) !important;
    height: calc(100% / 3) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    user-select: none;
  }

  .col-pimple:nth-child(2) {
    margin: 150px 0 0 300px;
  }
  .col-pimple:nth-child(3) {
    margin: 500px 0 0 0;
  }
  .col-pimple:nth-child(4) {
    margin: 0 0 0 -200px;
  }
  .col-pimple:nth-child(5) {
    margin: 300px 0 0 -50px;
  }
  .col-pimple:nth-child(6) {
    margin: -300px 0 0 700px;
  }

  .col-pimple:nth-child(7) {
    margin: -1400px 0 0 400px;
  }

  .cart-footer {
    position: absolute;
    padding: 0 100px 0 0;
    right: 15%;
    bottom: -10%;
    z-index: 10 !important;
  }
}
</style>
