export default {
  name: 'VendingMachineTracker',
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile;
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
  },
  methods: {
    postTracker(status, score, code) {
      const loc = Cookies.get('store_location_id');
      const token = Cookies.get('token');
      const source = this.isVnSource ? 'vn' : 'id';

      // close button
      let isPlayGame = false;
      let voucherCode = 'NO_REWARD';
      let points = 0;

      // close button while playing
      if (status == 'play_close') {
        isPlayGame = true;
      }
      // finished playing
      if (status == 'finish') {
        if (!score || score == 0) {
          isPlayGame = false;
        } else {
          isPlayGame = true;
          points = score;
        }
        voucherCode = code ? code : 'NO_REWARD';
      }

      if (loc) {
        // for vending machine game (BBB, SBS)
        const body = {
          'SOC-PLATFORM': 'vending-machine',
          source: source,
          store_id: loc,
          user_id: this.userProfile.id, //
          is_play_game: isPlayGame,
          voucher_code: voucherCode,
          points: points,
        };
        if (token) {
          body['Authorization'] = `Bearer ${token}`;
        }

        this.$MS_SOCO_PUBLIC_API_URL
          .post(`/vending-machine-tracker`, body)
          .then((res) => {
            if (res.status == '200') {
              const path = Cookies.get('vending_machine_device') == 'true' ? 'home' : 'vmcola';
              this.$store.dispatch('logout', path === 'vmcola' ? '/vmcola/home' : '/vending-machine/' + path);
              this.$toasted.global.show('Thank you and see you again soon!');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
